import React, { useState } from 'react';
import Helmet from 'react-helmet';

import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import Copy, { Bold } from '@nib-components/copy';
import Card from '@nib/card';
import colors from '@nib-components/colors';
import { Stack, Tiles, Section } from '@nib/layout';
import { DoctorGraphicIcon, OutpatientGraphicIcon, HospitalGraphicIcon } from '@nib/icons';
import { PrimaryButton } from '@nib-components/button';

import desktop from '../img/health-insurance/hero/desktop.jpg';
import desktop2x from '../img/health-insurance/hero/desktop@2x.jpg';
import mobile from '../img/health-insurance/hero/mobile.jpg';
import mobile2x from '../img/health-insurance/hero/mobile@2x.jpg';
import tablet from '../img/health-insurance/hero/tablet.jpg';
import tablet2x from '../img/health-insurance/hero/tablet@2x.jpg';

import CallBackModuleBase from '../components/CallBackModal';
import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import UtilityButtons from '../components/UtilityButtons';
import George from '../components/George';
import MemberStory from '../components/MemberStory';
import WhyChooseNib from '../components/shared/WhyChooseNib';
import {
  ContentWrapper,
  Wrapper,
  BackgroundImageWrapper,
  ReducedGapCard,
  HeroPanelCopy,
} from '../components/styledComponents';
import { ContentfulMemberStoryNode, UtilityLinkModel } from '../constructs/models';
import { urlConstants } from '../constructs/constants';
import metrics from '../metrics';

import styled from 'styled-components';
import { pb } from 'styled-components-spacing';

import insuranceIsWorthIt from '../img/health-insurance/insurance-is-worth-it.jpg';

const title = 'Private Health Insurance Plans';
const description =
  'Do you need health insurance in New Zealand? Have peace of mind and make your health easier to manage with nib.';

const HealthInsurance = (): JSX.Element => {
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'MedicalOrganization',
    '@id': 'MedicalOrganization',
    url: 'https://www.nib.co.nz/health-insurance',
    address: '48 Shortland Street, Auckland CBD, Auckland 1010',
    mainEntityOfPage: 'https://www.nib.co.nz/health-insurance',
    name: 'nib nz insurance limited',
  };
  const JSONschema = JSON.stringify(schema);

  return (
    <Layout>
      <div>
        <Helmet>
          <title>{title} | nib</title>
          <meta name="description" content={description} />
          <script type="application/ld+json">{JSONschema}</script>
        </Helmet>
        <HeroPanelSection />
        <HowInsuranceWorksSection />
        <NavigationSection />
        <HealthInsuranceInfoSection />
        <UninsuredCostsSection />
        <GeorgeSection />
        <GettingTheRightCoverSection />
        <WhyChooseNib id="WhyChooseNib" left="OurFocus" middle="OneOnOne" right="OurMembers" />
        <MemberStorySection />
      </div>
    </Layout>
  );
};

const HeroPanelSection = (): JSX.Element => {
  return (
    <HeroPanel
      title="Health Insurance"
      variation="condensed"
      images={{
        desktop: desktop,
        desktop2x: desktop2x,
        mobile: mobile,
        mobile2x: mobile2x,
        tablet: tablet,
        tablet2x: tablet2x,
      }}
    >
      <HeroPanelCopy>
        Health insurance gives you peace of mind and makes good health easier to manage.
      </HeroPanelCopy>
      <PrimaryButton href={urlConstants.getQuickQuote()}>Get a quote</PrimaryButton>
    </HeroPanel>
  );
};

const HowInsuranceWorksSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={4}>
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="trueGreen">
          How health insurance works
        </Heading>
        <Copy measure={false}>
          Private health insurance helps protect you from the unexpected. It’s an alternative to
          public, government funded healthcare and may offer a broader range of treatment options.
        </Copy>
        <Copy measure={false}>
          Depending on the cover you choose, health insurance can help cover you for the big things
          like seeing a specialist, surgery or cancer treatment, as well as everyday health needs
          like the dentist, GP and physio. You can buy private health insurance directly from a
          health insurer like nib, through an independent financial advice provider, or it may be
          available through your employer.
        </Copy>
      </Stack>
    </Section>
  );
};

const NavigationSection = (): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const utilityLinks: UtilityLinkModel[] = [
    {
      title: 'Why nib health insurance?',
      url: '/health-insurance#WhyChooseNib',
    },
    {
      subtitle: 'Looking for a plan?',
      title: 'Compare options',
      url: '/compare-plans',
    },
    {
      subtitle: 'How much will it cost?',
      title: 'Get a quote',
      url: urlConstants.getQuickQuote(),
    },
    {
      subtitle: 'Need help joining?',
      title: 'Ask an expert',
      onClick: () => setIsOpen(true),
    },
  ];

  return (
    <Section role="section" background="warmWhite60" padding={4}>
      <CallBackModuleBase variant="Expert" isOpen={isOpen} setOpen={setIsOpen} />
      <UtilityButtons buttons={utilityLinks} flex={true} />
    </Section>
  );
};

const HealthInsuranceInfoSection = (): JSX.Element => (
  <Section role="section">
    <Stack space={{ xs: 2, md: 4 }}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h3" color="trueGreen">
        Why health insurance is worth it
      </Heading>
      <Wrapper>
        <ContentWrapper>
          <Stack space={{ xs: 2, md: 4 }}>
            <Copy measure={false}>
              The public health system in New Zealand offers free or low cost treatment at public
              hospitals for Kiwis, but high demand and the rising cost of providing healthcare
              creates challenges.
            </Copy>
            <Copy measure={false} component="div">
              <ul>
                <li>
                  If you need non-urgent treatment, you might be put on a waiting list which can
                  lead to stress and uncertainty. How long you wait may depend on the severity of
                  your condition.
                </li>
                <li>
                  If your condition is not accident-related, treatment costs won’t be covered by ACC
                  (the Accident Compensation Corporation).
                </li>
                <li>You may have less control over when and where you are treated.</li>
                <li>
                  You have to pay the costs of drugs that aren’t funded by PHARMAC (including any
                  drug administration costs).
                </li>
              </ul>
            </Copy>
            <Copy measure={false}>That’s where private health insurance comes in.</Copy>
            <Copy measure={false} component="div">
              <ul>
                <li>
                  You have the choice of private treatment and surgery without relying on the public
                  health system.
                </li>
                <li>You can choose who you see and when you are treated.</li>
                <li>
                  Faster treatment means you can get back on your feet quicker, whether that be for
                  work or play.
                </li>
                <li>
                  You have peace of mind as your health insurer will cover approved costs for
                  expensive private treatments and surgeries.
                </li>
                <li>
                  You can choose to add extra cover for{' '}
                  <Link href={urlConstants.nonPharmac}>non-PHARMAC funded drugs</Link>.
                </li>
              </ul>
            </Copy>
          </Stack>
        </ContentWrapper>
        <BackgroundImageWrapper backgroundImage={insuranceIsWorthIt} />
      </Wrapper>
    </Stack>
  </Section>
);

const UninsuredCostsSection = (): JSX.Element => {
  return (
    <Section role="section" background="sageGreen60">
      <Stack space={4}>
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h3" color="trueGreen">
          What will it cost me if I’m uninsured?
        </Heading>
        <Copy measure={false}>
          Health insurance helps cover the costs of private treatment, as well as giving you more
          control over who treats you and when. Without it, private treatment could be unaffordable
          or make a significant impact on your hard-earned savings and lifestyle.
        </Copy>
        <Copy measure={false}>
          Take a look at what some common private treatments could cost in New Zealand if you choose
          to pay for them yourself, without health insurance<sup>1</sup>.
        </Copy>
        <Tiles space={{ xs: 2, xl: 4 }} columns={{ sm: 1, md: 3 }}>
          <EverydayTile />
          <OutpatientTile />
          <HospitalTile />
        </Tiles>
        <Copy measure={false}>
          <sup>1</sup> nib average claim statistics for Everyday and GP minor surgery claims during
          2020 and for Outpatient and Hospital claims from October 2020 to February 2021. This is an
          illustration of treatments and costs you could expect to pay if treated without health
          insurance contribution for Everyday healthcare or in private hospital for Outpatient and
          Hospital healthcare. This is not a representation of conditions covered on your policy or
          the amount that would be paid by nib in the event of a claim.
        </Copy>
      </Stack>
    </Section>
  );
};

const EverydayTile = (): JSX.Element => {
  return (
    <ReducedGapCard withBoxShadow={true} height="100%" icon={DoctorGraphicIcon}>
      <Card.Content title="" titleComponent="div">
        <TileHeaderRow treatmentTitle="Everyday" />
        <TileRowContent text="GP consultations" minCost={35} maxCost={60} />
        <TileRowContent text="Dental" minCost={270} maxCost={400} />
        <TileRowContent text="Optical" minCost={260} maxCost={400} />
        <TileRowContent text="Acupuncture" minCost={50} maxCost={80} />
        <TileRowContent text="Physiotherapy" minCost={45} maxCost={70} />
        <TileRowContent text="Mental health consultations" minCost={170} maxCost={255} />
      </Card.Content>
    </ReducedGapCard>
  );
};

const OutpatientTile = (): JSX.Element => {
  return (
    <ReducedGapCard withBoxShadow={true} height="100%" icon={OutpatientGraphicIcon}>
      <Card.Content title="" titleComponent="div">
        <TileHeaderRow treatmentTitle="Outpatient" />
        <TileRowContent text="Specialist consultations" minCost={160} maxCost={280} />
        <TileRowContent text="GP minor surgery" minCost={325} maxCost={400} />
        <TileRowContent text="Ultrasounds" minCost={500} maxCost={1500} />
        <TileRowContent text="MRI scans" minCost={1500} maxCost={3000} />
        <TileRowContent text="CT scans" minCost={1300} maxCost={1700} />
        <TileRowContent text="PET scans" minCost={2000} maxCost={3200} />
      </Card.Content>
    </ReducedGapCard>
  );
};
const HospitalTile = (): JSX.Element => {
  return (
    <ReducedGapCard withBoxShadow={true} height="100%" icon={HospitalGraphicIcon}>
      <Card.Content title="" titleComponent="div">
        <TileHeaderRow treatmentTitle="Hospital" />
        <TileRowContent text="Wisdom teeth extraction" minCost={3500} maxCost={5200} />
        <TileRowContent
          text="Grommets (insertion of tubes in the eardrum)"
          minCost={2200}
          maxCost={3500}
        />
        <TileRowContent text="Chemotherapy" minCost={15000} maxCost={170000} />
        <TileRowContent text="Radiotherapy" minCost={20000} maxCost={55000} />
        <TileRowContent text="Varicose veins" minCost={7000} maxCost={10500} />
        <Copy>View more common surgery costs below</Copy>
      </Card.Content>
    </ReducedGapCard>
  );
};

interface TileRowContentProps {
  text: string;
  minCost: number;
  maxCost: number;
}

const TileRowContent = (props: TileRowContentProps): JSX.Element => {
  const { text, minCost, maxCost } = props;
  return (
    <SpreadRow>
      <div style={{ width: '55%' }}>{text}</div>
      <div style={{ width: '45%', textAlign: 'right' }}>
        ${minCost.toLocaleString()} - ${maxCost.toLocaleString()}
      </div>
    </SpreadRow>
  );
};

interface TileHeaderRowProps {
  treatmentTitle: string;
}

const TileHeaderRow = (props: TileHeaderRowProps) => {
  const { treatmentTitle } = props;
  return (
    <SpreadRow>
      <Bold>{treatmentTitle} Treatment</Bold>
      <Bold align="right">Average treatment cost (NZD)</Bold>
    </SpreadRow>
  );
};

const SpreadRow = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.sneezy};
  justify-content: space-between;
  align-items: center;
  ${pb(2)}
`;

const GeorgeSection = (): JSX.Element => {
  return (
    <Section role="section">
      <Stack space={{ xs: 4, xl: 7 }}>
        <George />
        <Copy measure={false}>
          <sup>2</sup> nib claim statistics for October 2020 to February 2021. This is an
          illustration of treatments and costs you could expect to pay if treated without health
          insurance contribution in private hospital. This is not a representation of conditions
          covered on your policy or the amount that would be paid by nib in the event of a claim.
        </Copy>
      </Stack>
    </Section>
  );
};

const GettingTheRightCoverSection = (): JSX.Element => {
  return (
    <Section role="section" background="warmWhite60">
      <Stack space={4}>
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h3" color="trueGreen">
          Find the right cover for your needs
        </Heading>
        <Copy measure={false}>
          It's important to make sure your health insurance matches your needs and lifestyle. There
          are two main types of cover:
        </Copy>
        <Copy measure={false} component="div">
          <ul>
            <li>
              Everyday: cover for day-to-day healthcare needs like dentist, GP or physio visits.
            </li>
            <li>
              Hospital: cover for the big things like surgery or cancer treatment, outpatient health
              costs like seeing a specialist or surgeon, and diagnostic investigations.
            </li>
          </ul>
        </Copy>
        <Copy measure={false}>
          As well as what type of cover, you’ll need to consider who you’d like cover for - whether
          that’s just yourself, your partner or family.
        </Copy>
        <Copy measure={false}>
          You also have some flexibility to pick an excess level if you choose a plan with Hospital
          cover. Your excess level will affect the amount you pay in premiums – a higher excess
          means a lower premium.
        </Copy>
        <Copy measure={false}>
          Take a closer look at <Link href="/compare-plans">our plans</Link> and{' '}
          <Link href={urlConstants.getQuickQuote()}>get a quote</Link> online or, if you’d like to
          discuss your options, call us on <Link href="tel:0800123642">0800 123 642</Link>.
        </Copy>
      </Stack>
    </Section>
  );
};

const MemberStorySection = (): JSX.Element => {
  const memberStory: ContentfulMemberStoryNode = {
    title: 'Angelina’s story:',
    description: {
      childMarkdownRemark: {
        html: `<p>“It’s wonderful…huge peace of mind.” After losing her sister
        to a brain tumour, health insurance has given Angelina the confidence
        that she can access the care she needs, when she needs it.</p>`,
      },
    },
    videoUrl: 'https://www.youtube.com/watch?v=W2jHt9bnrn4&t=1s&ab_channel=nibNewZealand',
  };

  return (
    <Section role="section" background="white">
      <MemberStory videoOnRight={true} memberStory={memberStory} showButton={false} />
    </Section>
  );
};

export default metrics({ pageName: 'health-insurance' })(HealthInsurance);
