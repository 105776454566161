import React from 'react';

import styled, { ThemeProvider } from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import George from './images/george.jpg';

const theme = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

const ParentWrapper = styled.div`
  display: flex;
  justify-content: left;
  ${breakpoint('lg')`
    justify-content: center;
    `};
`;

const GeorgeImg = styled.img`
  display: none;
  ${breakpoint('sm')`
      max-width: 100%;
      height: auto;
      display: block;
  `};
`;
GeorgeImg.displayName = 'GeorgeImg';

const TextOnlyWrapper = styled.div`
  max-width: 100%;
  height: auto;
  display: block;
  ${breakpoint('sm')`
    display: none;
  `};
`;

const SectionHeader = styled.div`
  display: block;
  font-weight: bold;
  ${breakpoint('lg')`
        display: none;
    `};
`;
SectionHeader.displayName = 'SectionHeader';

const TextWrapper = styled.div`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
`;

const Title = styled.div`
  color: #666666;
`;

const Description = styled.div`
  color: #b7b7b7;
`;

const GeorgeSection = (): JSX.Element => {
  return <GeorgeImg src={George} data-testid="george-img" />;
};

const HeadSection = (): JSX.Element => {
  return (
    <div>
      <SectionHeader data-testid="section-header-head">Head</SectionHeader>
      <TextWrapper>
        <Title>
          Cataract surgery <strong>$3,200 - $4,900</strong>
        </Title>
        <Description>Removal of the cloudy lens from the eye affecting vision</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Endoscopic sinus surgery <strong>$12,000 - $23,000</strong>
        </Title>
        <Description>Surgical repair of the facial sinuses</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Wisdom teeth removal <strong>$3,500 – $5,200</strong>
        </Title>
        <Description>Surgical removal of wisdom teeth (third molars)</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Thyroidectomy <strong>$11,000 to $15,500</strong>
        </Title>
        <Description>Surgical removal of the thyroid gland</Description>
      </TextWrapper>
    </div>
  );
};

const HeartSection = (): JSX.Element => {
  return (
    <div>
      <SectionHeader data-testid="section-header-heart">Heart</SectionHeader>
      <TextWrapper>
        <Title>
          Angioplasty <strong>$17,500 – $31,000</strong>
        </Title>
        <Description>Insertion of a catheter to unblock artery with a stent or balloon</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Single valve heart operation <strong>$55,000 – $80,000</strong>
        </Title>
        <Description>Surgery to repair or replace a heart valve</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Heart bypass <strong>$40,000 – $55,000</strong>
        </Title>
        <Description>An operation to rechannel blood flow to the heart</Description>
      </TextWrapper>
    </div>
  );
};

const BodySection = (): JSX.Element => {
  return (
    <div>
      <SectionHeader data-testid="section-header-body">Body</SectionHeader>
      <TextWrapper>
        <Title>
          Mastectomy <strong>$13,000 – $30,000</strong>
        </Title>
        <Description>Surgical removal of the breast</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Spinal fusion <strong>$22,000 – $60,000</strong>
        </Title>
        <Description>Surgery to join two or more vertebrae together</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Gastroscopy <strong>$1,100 – $2,500</strong>
        </Title>
        <Description>A diagnostic procedure to look at the mouth, throat and stomach</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Cholecystectomy <strong>$9,000 – $12,000</strong>
        </Title>
        <Description>Surgical removal of the gall bladder</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Colonoscopy <strong>$2,000 – $3,200</strong>
        </Title>
        <Description>
          A diagnostic procedure to look at the large bowel and sample tissue
        </Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Hysterectomy <strong>$15,000 - $25,000</strong>
        </Title>
        <Description>Surgical removal of the uterus</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Appendectomy <strong>$9,000 – $13,000</strong>
        </Title>
        <Description>Surgical removal of the appendix</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Hernia repair <strong>$7,500 – $18,000</strong>
        </Title>
        <Description>Surgical repair of weakness in the abdominal wall</Description>
      </TextWrapper>
    </div>
  );
};

const LegSection = (): JSX.Element => {
  return (
    <div>
      <SectionHeader data-testid="section-header-leg">Leg</SectionHeader>
      <TextWrapper>
        <Title>
          Total hip joint replacement <strong>$22,000 – $28,000</strong>
        </Title>
        <Description>Replacement of the hip joint with an artificial joint</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Resection of endometriosis <strong>$9,000 – $16,000</strong>
        </Title>
        <Description>Surgical removal of the uterine cells from the pelvis or abdomen</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Robotic Prostatectomy <strong>$30,000 – $35,0000</strong>
        </Title>
        <Description>Robotic surgical removal of the prostate</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Total knee joint replacement <strong>$22,000 – $28,000</strong>
        </Title>
        <Description>Replacement of the knee joint with an artificial joint</Description>
      </TextWrapper>
      <TextWrapper>
        <Title>
          Varicose veins (both legs) <strong>$7,000 – $10,500</strong>
        </Title>
        <Description>Laser or surgical removal of varicose veins</Description>
      </TextWrapper>
    </div>
  );
};

const GeorgeComponent = (): JSX.Element => {
  return (
    <ThemeProvider theme={theme}>
      <ParentWrapper>
        <GeorgeSection />
        <TextOnlyWrapper>
          <HeadSection />
          <HeartSection />
          <BodySection />
          <LegSection />
        </TextOnlyWrapper>
      </ParentWrapper>
    </ThemeProvider>
  );
};

export default GeorgeComponent;
